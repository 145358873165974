// Remember to use percentages % so that it scales to all screensizes
.sliding{
    font-family:'Montserrat', sans-serif;
    z-index: 401 !important; // map pane is z index 400
    position: fixed;
    bottom: 0%;
    width: 99.5%;
    padding: 0%;
    padding-bottom: 0.5%;
    margin: 0% auto;
   
    .slidingDiv , .swiper-slide{
        z-index: 100000000 !important;
        min-height: 100px;
        height: 100%;
        background: #FFFFFF;
        height: 100%;
        max-height: 100px ;  
        width: 100%;
        margin:  0%;
        padding: 2% 0% ;
        background: #FFFFFF;
        filter: drop-shadow(0px 4px 4px  rgba(0, 0, 0, 0.25));
        border-radius: 10px;
    }
    .listButton{
        position: absolute;
        top: -55px;
        right: 16px;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        filter: drop-shadow(-2px 2px 2px  rgba(0,0,0,0.5));
        background-color: white;
        border: none;
        padding: 0%;
        margin: 0%;
        .listButtonIcon{
            padding: 0% auto;
            margin: 0% auto;
            color: #006C98;
        }
    }
    .gpsButton{
        position: absolute;
        top: -110px;
        right: 16px;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        filter: drop-shadow(-2px 2px 2px  rgba(0,0,0,0.5));
        background-color: white;
        border: none;
        padding: 0%;
        margin: 0%;
        .gpsIcon{
            padding: 0% auto;
            margin: 0% auto;
            color: #006C98;
        }
    }
    
}

.admin{
    font-family:'Montserrat', sans-serif;
    z-index: 401 !important; // map pane is z index 400
    position: absolute;
    bottom: 0%;
    width: 99.5%;
    padding: 0%;
    padding-bottom: 0.5%;
    margin: 0% auto;
   
    .slidingDiv , .swiper-slide{
        min-height: 60px;
        height: 100%;
        background: #FFFFFF;
        height: 100%;
        max-height: 60px !important;  
        width: 100%;
        margin:  0%;
        margin-left:  0.5%;
        margin-bottom:  1.5%;
        padding: 2% 0% ;
        background: #FFFFFF;
        filter: drop-shadow(0px 4px 4px  rgba(0, 0, 0, 0.25));
        border-radius: 10px;
    }
    .gpsButton{
        position: absolute;
        top: -50px;
        right: 16px;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        filter: drop-shadow(-2px 2px 2px  rgba(0,0,0,0.5));
        background-color: white;
        border: none;
        padding: 0%;
        margin: 0%;
        .gpsIcon{
            padding: 0% auto;
            margin: 0% auto;
            color: #006C98;
        }
    }
}