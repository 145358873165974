// Remember to use percentages % so that it scales to all screensizes
.building{
   position: relative;
   bottom: 10px;
   left: -110px;
   width: 100px;
   text-align: right;
   .name{
       font-weight: 900;
       font-size: 1.25em;
       text-overflow: ellipsis;
       margin: 0px;
       padding: 0px;
       line-height: 1em;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
   }
   .dist{
        font-size: 1em;
        line-height: .75em;
   }
}

