// Remember to use percentages % so that it scales to all screensizes
.drawer{
    :global(.MuiDrawer-paper ){
        position: relative !important;
    }
    .children{
        overflow: scroll;
    }
}
.viewMapButton{
    position: absolute;
    bottom: 3%;
    right: 5%;
    width: 131px;
    height: 48px;
    border-radius: 30px;
    filter: drop-shadow(-2px 2px 2px  rgba(0,0,0,0.5));
    background-color: white;
    border: none;
    color: #006C98;
    div{
        display: flex;
        width: 110px;
        margin: 0% auto;
        align-items: center;
            p{
                padding-left: 8px;
                float: left; 
                font-family:'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
            }
    }
}
