.map{
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
}
.adminMap{
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
}


.blueDot{
    color:  rgb(30, 143, 255);
    position: absolute;
    left: -4px;
    top: -4px;
}
.blueRadius{
    border: rgba(30, 143, 255, 0.15) 0px solid;
    background-color: rgba(30, 143, 255, 0.15) !important;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    position: absolute;

}