.building {
  font-family:'Montserrat', sans-serif;
  width: 90%;
  // to center in card or list
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);// to center in card or list end

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
   .trafficIconWrapper {
     align-content: center;
     max-width: 25%;
     font-weight: 600;
     margin-right: 1em;
     .manIcon {
       width: 125%;
       height: auto;
       position: relative;
       left: -15%;
     }
   }
  .buildingInfoWrapper {
    align-self: flex-start;
    width: 75%;
    .buildingName {
      font-size: 1.1em;
      font-weight: 600;
      flex-direction: column;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .trafficScore {
      flex-direction: column;
      font-size: .9em;
      color: #757575;
      font-weight: 500;
    }
    .details {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      color: #333333;
      font-weight: 600;
      margin-top: 3%;

      .meetingIcon , .calendarIcon {
        margin: 0;
        margin-right: 10px;
        padding: 0;
        font-size: .75em;
        display: inline-flex;
        vertical-align: middle;
        span{
          margin: auto 4px;
          padding-top: .5px;
        }
      }
      .meetingIcon{
        svg{
          margin: auto 0px;
          height: 20.5px;
          width: 20.5px;
        }
      }
      .calendarIcon {
        svg{
          margin: auto 0px;
          height: 18px;
          width: 18px;
          padding: 1.5px;
        }
      }
    }
  }
}