    .icon , .largeIcon {
        margin: 0px;
        padding: 0px;
        height: 20px !important;
        width: 20px !important;
        filter: drop-shadow(0px 0px 0px  rgba(0,0,0,0));
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .largeIcon {
        height: 35px !important;
        width: 35px !important;
        top: 30%;
    }
    .name{
        position: absolute;
        width: 100%;
        left: 70%;
        top: 10%;
        z-index: 100;
        font-family:'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;

        color: #333333;
    }
    .green{ 
        padding: 0px;
        margin: 0px;  
        position: relative;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        background: radial-gradient(circle at center, 
                                                rgba(77, 155, 50, 0.7) 0%,
                                                rgba(77, 155, 50, 0.35) 35.42%, 
                                                rgba(196, 196, 196, 0) 100%);
    } 
    .yellow{   
        position: relative;
        padding: 0px;
        margin: 0px; 
        height: 62.5px;
        width: 62.5px;
        border-radius: 31px;
        background: radial-gradient(circle at center, 
                                                rgba(251, 132, 45, 0.7) 0%, 
                                                rgba(251, 192, 45, 0.7) 20.31%,
                                                rgba(255, 227, 78, 0.7) 37.5%, 
                                                rgba(77, 155, 50, 0.357) 66.15%, 
                                                rgba(77, 155, 50, 0.007) 87.4%, 
                                                rgba(196, 196, 196, 0) 100%);
    }
    .red{   
        position: relative;
        padding: 0px;
        margin: 0px; 
        height: 75px;
        width: 75px;
        border-radius: 37.5px;
        background: radial-gradient(circle at center, 
                                                rgba(176, 0, 39, 0.7) 18.23%, 
                                                rgba(251, 132, 45, 0.7) 28.65%, 
                                                rgba(251, 192, 45, 0.7) 39.06%, 
                                                rgba(255, 227, 78, 0.7) 45.83%, 
                                                rgba(77, 155, 50, 0.357) 65.53%, 
                                                rgba(77, 155, 50, 0.007) 87.4%,
                                                rgba(196, 196, 196, 0) 100%);
    }

    :global(div.leaflet-div-icon) {
        background: transparent; 
        border: 0px solid transparent; 
    }
    :global(.leaflet-popup-content-wrapper ) , :global( .leaflet-popup-close-button ) , :global(.leaflet-popup-tip-container){
        display: none;
    }