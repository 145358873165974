.pod{
    font-family:'Montserrat', sans-serif;
    width: 90%;
    // to center in card or list
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);// to center in card or list end
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .iconTitle{
        margin: 1% 0%;
        padding: 0px;
        font-size: 1em;
        font-weight: 600;
        flex-direction: row;
        display: inline-flex;
        vertical-align: middle;
            svg{
                margin: auto 0px;
                height: 14px;
                width: 14px;
            }
            .red{
                color: #B00020
            }
            .yellow{
                color: #FBC02D;
            }
            .green{
                color: #4D9B32;
            }
            span{
                margin: auto;
                margin-left: 4px;
            }
    }
   
    .description {
        flex-direction: column;
        font-size: .9em;
        color: #757575;
        font-weight: 500;
        margin: 1% 0px;
        padding: 0px 2px ;
      }
        .button{
            font-size: .85em;
            text-transform: none;
            position: absolute;
            right: 0%;
            bottom: 0%;
            padding: 0px;
            border-radius: 5px;
            width: 89px;
            height: 36px;
        }
        .holdme{
            background: #006C98 !important;//override material UI
            color: white !important; //override material UI
        }
        .holding{
            color: #006C98 !important; //override material UI
            background: white !important; //override material UI
            border: 1px solid #BDBDBD !important; //override material UI
        }
    
    
}