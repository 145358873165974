.ListView{
    font-family:'Montserrat', sans-serif;
    height: 100%;
    width: 100%;
   
    .listItem{
        height: 111px ;  
        width: calc(100% - 32px);
        position: relative;
        margin: 0px auto;
        padding: 1% 0%;
        &::after{
            position: absolute;
            content: "";
            height: 2px;
            background-color: #E0E0E0;
            width: 109.5%;
            overflow: hidden;
            margin-left: -5%;
            bottom: 0%;
        }
    }
    .returnToBuilding{
        width: 100%;
        height: 48px;
        margin:  0px;
        position: relative;
        background-color: #F5F5F5;
        border: none;
        .iconTitle{
            margin: 0px;
            padding: 0px;
            position: absolute;
            top: 50%;
            left: 10%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);// to center vertically

            font-size: 1.1em;
            font-weight: 600;
            flex-direction: row;
            display: inline-flex;
            vertical-align: middle;
                svg{
                    margin: auto 0px;
                }
                span{
                    margin: auto;
                    margin-left: 10px;
                    font-family:'Montserrat', sans-serif;
                    font-weight: 600;
                }
        }

        .trafficIconWrapper {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 90%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);// to center vertically
            
            .manIcon {
                width: 100%;
                height: auto;
            }
        }
    }

}