.marker-cluster-small , .marker-cluster-medium , .marker-cluster-large {
    background:rgba(51, 51, 51, 0.5) 
	}
.marker-cluster-small div ,.marker-cluster-medium div , .marker-cluster-large div {
	background-color: rgb(51, 51, 51);
	}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 900;
	}
.marker-cluster span {
	line-height: 30px;
	color: white;
	}