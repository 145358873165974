.outside{
    padding: 0px;
    margin: 0px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-family:'Montserrat', sans-serif;
    position: relative;
    height: 100vmax;
    width: 100vmax;
}
.admin{
    height: 656px;
    padding: 0px;
    margin: 0px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-family:'Montserrat', sans-serif;
    position: relative;
}
